<template>
    <div class="classify_add_eidt">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="500px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">反馈详情</div>
            </div>
            <ul class="dialog_a">
                <li>
                    <span class="a_left f_s3 color7">联系人</span>
                    <span class="a_right f_s3 color5">{{info.nickname}}</span>
                </li>
                <li>
                    <span class="a_left f_s3 color7">联系方式</span>
                    <span class="a_right f_s3 color5">{{info.contact}}</span>
                </li>
                <li>
                    <span class="a_left f_s3 color7">提交人</span>
                    <span class="a_right f_s3 color5">{{info.member_name}}</span>
                </li>
                <li>
                    <span class="a_left f_s3 color7">反馈内容</span>
                    <span class="a_right f_s3 color5">{{info.content}}</span>
                </li>
            </ul>
            <div slot="footer">
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            dialogVisible: false,
            // 
            info: {},
        };
    },

    created() {

    },

    mounted() { },

    methods: {
        // 打开
        openDialog(val) {
            this.info = val
            this.dialogVisible = true
        },
        affirmBtn() {
            this.dialogVisible = false
        },

    }
}
</script>
<style lang='scss' scoped>
.dialog_a {
    width: 100%;
    li {
        width: 100%;
        margin-top: 20px;
        overflow: hidden;
        padding: 0 10px 20px 0;
        span {
            display: block;
            box-sizing: border-box;
        }
        .a_left {
            float: left;
            width: 100px;
            text-align: right;
        }
        .a_right {
            float: right;
            width: 340px;
            padding-left: 20px;
        }
    }
}
</style>