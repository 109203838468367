<template>
    <div class="text_vue">
        <!-- 标题 -->
        <div class="vue_title">意见反馈</div>
        <!--  -->
        <div class="text_main">
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="id" label="ID" width="100"></el-table-column>
                    <el-table-column prop="nickname" label="联系人" width="200"></el-table-column>
                    <el-table-column prop="contact" label="联系方式" width="200"></el-table-column>
                    <el-table-column label="反馈内容">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" placement="top-start">
                                <div class="slot_content" slot="content">{{ scope.row.content }}</div>
                                <div class="row_content one-txt-cut">{{ scope.row.content }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" width="200"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="130">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="feedbackDetail(scope.row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 详情 -->
        <feedback-detail ref="feedback"></feedback-detail>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import FeedbackDetail from '../components/feedback/detail.vue'// 详情
export default {
    components: { EmptyData, PagingPage, FeedbackDetail },
    props: {},
    data() {
        return {
            list: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
        };
    },


    created() {
        this.getList()
    },


    methods: {
        // 数据列表
        getList() {
            this.loading = true
            var params = {
                page: this.page,
                limit: this.limit,
            }
            this.$helpApi.feedbackList(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },

        //详情
        feedbackDetail(val) {
            this.$refs.feedback.openDialog(val)
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
    }
}
</script>
<style lang='scss' scoped>
.text_vue {
    width: 100%;
    .text_main {
        width: 100%;
        padding: 20px 20px 0;
    }
}
.slot_content{
    max-width: 500px;
    line-height: 25px;
}
</style>